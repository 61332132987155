<template>
  <div>
    <div class="row">
      <div class="col-3 p-sm-1">
        <b-form-select v-show="listUsers && selectedRows.length > 0" v-model="selected" :options="listUsers" @input="setUsers(selectedRows, selected)" class="select--users--assign">
          <template #first>
            <b-form-select-option value="" disabled>Por favor selecciona un usuario para asignar</b-form-select-option>
            <b-form-select-option value="0">Desasignar</b-form-select-option>
          </template>
        </b-form-select>
      </div>
    </div>
    <table-render :schema="schema" :rows="rows" :showCheckboxes="permission.teamLeader" :selectedRows.sync="selectedRows" :fixed="true">
      <template #prioritySlot="scope">
        <feather-icon icon="CircleIcon"
          :style="[{'color': `${scope.rowdata.priority.color}`, fill: `${scope.rowdata.priority.color}`}] " />
        {{scope.rowdata.priority.label}}
      </template>
      <template #actionsSlot="scope">
        <div class="container--action" v-show="permission.teamLeader">
          <drop-down-assign
            :userAssign="scope.rowdata.user_assignment"
            :indexTicket="scope.rowdata.id"
            :listOptions="scope.rowdata.listUsers"
            @updateListUsers="setUserAssign($event)">
          </drop-down-assign>
        </div>
          <!-- <b-button variant="flat-success" v-b-tooltip.hover title="Completar tarea" class="p-0" @click="completeTask(scope.rowdata.id, scope.rowdata.user_assignment)"><feather-icon icon="CheckIcon" /></b-button> -->
          <b-button variant="flat-success" v-b-tooltip.hover title="Ver Delivery" class="p-0" @click="openDelivery(scope.rowdata)"><feather-icon icon="TruckIcon" /></b-button>
          <b-button variant="flat-success" v-b-tooltip.hover title="Ver Ticket" class="p-0" @click="openTicket(scope.rowdata)"><feather-icon icon="FileTextIcon" /></b-button>
      </template>
      <template #countrySlot="scope">
        <p>{{setCountry(scope.rowdata.country_code)}}</p>
      </template>
      <template #resolutionDateSlot="scope">
        <p class="m-0">{{scope.rowdata.resolution_date ? sliceString(scope.rowdata.resolution_date) : 'Sin información'}}</p>
      </template>
    </table-render>
    <pagination :pagination="pagination" :noDigits="pagination.noShowDigits" :showSize="true"/>
  </div>
</template>
<script>
import BaseServices from '@/store/services/index'
import dropDownAssign from './dropDownAssign.vue'
import { environment } from '@/environments/environment'
export default {
  props: ['listAssign', 'listUsers', 'permission', 'pagination'],
  components: {
    dropDownAssign
  },
  data() {
    return {
      baseService: new BaseServices(this),
      schema: [],
      rows: [],
      actions: [],
      countries: {},
      selectedRows: [],
      selected: {}
    }
  },
  mounted() {
    this.setData()
  },
  computed: {
    dataUser() {
      return this.$session.get('cas_user')
    }
  },
  watch: {
    listAssign: {
      handler(value) {
        if (value) {
          this.rows = [...value]
        }
      },
      deep: true
    },
    listUsers: {
      handler(value) {
        this.rows = this.rows.map(el => ({
          ...el,
          listUsers: [...this.listUsers]
        }))
      },
      deep: true
    },
    'pagination.page'(value) {
      this.$emit('changePagination', value)
    },
    'pagination.limit'(value) {
      this.$emit('changeLimit', value)
    }
  },
  methods: {
    sliceString(dateString) {
      return dateString.slice(0, 10)
    },
    setData() {
      this.schema = [
        {label: 'ID', key: 'delivery_id', sortable: true},
        {label: 'Courier', key: 'ticket.carrier_name', sortable: true},
        {label: 'Empresa', key: 'ticket.company_name', sortable: true},
        {label: 'País', key: 'countrySlot', useSlot: true},
        {label: 'Categoría', key: 'category.category_name', sortable: true},
        {label: 'Asunto', key: 'ticket.current_type_name', sortable: true},
        {label: 'Estado Tracking', key: 'ticket.current_delivery_status_name', sortable: true},
        {label: 'Estado Ticket', key: 'ticket.current_status_agent_name', sortable: true},
        {label: 'Tipo de cuenta', key: 'ticket.contract_type', sortable: true},
        {label: 'Prioridad', key: 'prioritySlot', useSlot: true},
        {label: 'Fecha de Asignación', key: 'assignment_at'},
        {label: 'Fecha de resolución (etiqueta)', key: 'resolutionDateSlot', useSlot: true},
        {label: 'Acciones', key: 'actionsSlot',  useSlot: true, class: ['text-center thead--light']}
      ]
      this.countries = this.$i18nImport('es').es
    },
    setCountry(country) {
      return this.countries[country]
    },
    setUsers(idTicket, idUser) {
      if (idUser) {
        const queryParams = {
          tickets_assignment_id: idTicket,
          user: this.dataUser.email
        }
        if (idUser !== '0') queryParams.bo_user_id = idUser
        const service = idUser === '0' ? 'deleteUsersAssign' : 'setUsersAssign'
        this.baseService.callService(service, queryParams)
          .then(response => {
            this.updateFilters()
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    setUserAssign(data) {
      const dataUser = data.action === 'unAssign' ? '0' : data.id
      this.setUsers([data.indexTicket], dataUser)
    },
    updateFilters() {
      this.selected = null
      this.selectedRows = []
      this.$emit('updateList', true)
    },
    completeTask(idTicket, user) {
      if (idTicket) {
        const queryParams = {
          user_email: user.email,
          tickets_assignment_id: idTicket
        }
        this.baseService.callService('setCompleteAssign', queryParams)
          .then(response => {
            this.updateFilters()
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    openTicket(data) {
      window.open(`${environment.platformUrl}/${ !!data.ticket && !!data.ticket.delivery_type && data.ticket.delivery_type === 'return' ? 'return-deliveries' : 'tickets'}/${data.delivery_id}`, '_blank')
    },
    openDelivery(data) {
      window.open(`${environment.platformUrl}/${ !!data.ticket && !!data.ticket.delivery_type && data.ticket.delivery_type === 'return' ? 'return-deliveries' : 'deliveries'}/${data.delivery_id}`, '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
  .select--users--assign {
    border-color: #165191;
    color: #165191;
  }
  .container--action {
    display: flex;
    gap: 1rem;
    text-align: center;
    justify-content: center;
  }
</style>
