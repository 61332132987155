<template>
  <div>
    <table-render :schema="schema" :rows="rows" :showCheckboxes="permission.teamLeader" :selectedRows.sync="selectedRows" :fixed="true">
      <template #prioritySlot="scope">
        <feather-icon icon="CircleIcon"
          :style="[{'color': `${scope.rowdata.priority.color}`, fill: `${scope.rowdata.priority.color}`}] " />
        {{scope.rowdata.priority.label}}
      </template>
      <template #actionsSlot="scope">
        <div class="assign--complete-user" v-if="permission.teamLeader && scope.rowdata.user_assignment && !!scope.rowdata.user_assignment.email && !!scope.rowdata.user_solver && scope.rowdata.user_assignment.email === scope.rowdata.user_solver">
          <div class="drop--down-item">
            <img v-if="scope.rowdata.user_assignment.photo" :src="scope.rowdata.user_assignment.photo" alt="foto" class="drop--down-photo mr-1 ">
            <feather-icon class="drop--down-photo" v-else icon="UserIcon"/>
          </div>
          <span class="drop--down-item">{{scope.rowdata.user_assignment.name}}</span>
        </div>
        <b-button variant="flat-success" v-b-tooltip.hover title="Ver Delivery" class="p-0" @click="openDelivery(scope.rowdata)"><feather-icon icon="TruckIcon" /></b-button>
          <b-button variant="flat-success" v-b-tooltip.hover title="Ver Ticket" class="p-0" @click="openTicket(scope.rowdata)"><feather-icon icon="FileTextIcon" /></b-button>
      </template>
      <template #countrySlot="scope">
        <p>{{setCountry(scope.rowdata.country_code)}}</p>
      </template>
      <template #resolutionDateSlot="scope">
        <p class="m-0">{{scope.rowdata.resolution_date ? sliceString(scope.rowdata.resolution_date) : 'Sin información'}}</p>
      </template>
    </table-render>
    <pagination :pagination="pagination" :noDigits="pagination.noShowDigits" :showSize="true"/>
  </div>
</template>
<script>
import BaseServices from '@/store/services/index'
import { environment } from '@/environments/environment'
export default {
  props: ['listAssign', 'listUsers', 'permission', 'pagination'],
  components: {
  },
  data() {
    return {
      baseService: new BaseServices(this),
      schema: [],
      rows: [],
      countries: {},
      selectedRows: []
    }
  },
  mounted() {
    this.setData()
  },
  computed: {
    dataUser() {
      return this.$session.get('cas_user')
    }
  },
  watch: {
    listAssign: {
      handler(value) {
        if (value) {
          this.rows = [...value]
        }
      },
      deep: true
    },
    listUsers: {
      handler(value) {
        this.rows = this.rows.map(el => ({
          ...el,
          listUsers: [...this.listUsers]
        }))
      },
      deep: true
    },
    'pagination.page'(value) {
      this.$emit('changePagination', value)
    },
    'pagination.limit'(value) {
      this.$emit('changeLimit', value)
    }
  },
  methods: {
    sliceString(dateString) {
      return dateString.slice(0, 10)
    },
    setData() {
      this.schema = [
        {label: 'ID', key: 'delivery_id', sortable: true},
        {label: 'Courier', key: 'ticket.carrier_name', sortable: true},
        {label: 'Empresa', key: 'ticket.company_name', sortable: true},
        {label: 'País', key: 'countrySlot', useSlot: true},
        {label: 'Categoría', key: 'category.category_name', sortable: true},
        {label: 'Asunto', key: 'ticket.current_type_name', sortable: true},
        {label: 'Estado Tracking', key: 'ticket.current_delivery_status_name', sortable: true},
        {label: 'Estado Ticket', key: 'ticket.current_status_agent_name', sortable: true},
        {label: 'Tipo de cuenta', key: 'ticket.contract_type', sortable: true},
        {label: 'Prioridad', key: 'prioritySlot', useSlot: true},
        {label: 'Fecha de Finalización', key: 'solver_at', sortable: true},
        {label: 'Fecha de resolución (etiqueta)', key: 'resolutionDateSlot', useSlot: true},
        {label: 'Acciones', key: 'actionsSlot',  useSlot: true, class: ['text-center thead--light']}
      ]
      this.countries = this.$i18nImport('es').es
    },
    setCountry(country) {
      return this.countries[country]
    },
    openTicket(data) {
      window.open(`${environment.platformUrl}/${ !!data.ticket && !!data.ticket.delivery_type && data.ticket.delivery_type === 'return' ? 'return-deliveries' : 'tickets'}/${data.delivery_id}`, '_blank')
    },
    openDelivery(data) {
      window.open(`${environment.platformUrl}/${ !!data.ticket && !!data.ticket.delivery_type && data.ticket.delivery_type === 'return' ? 'return-deliveries' : 'deliveries'}/${data.delivery_id}`, '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
  .select--users--assign {
    border-color: #165191;
    color: #165191;
  }
  .assign--complete-user {
    display: flex;
    gap: .5rem;
    align-items: center;
  }
</style>
