<template>
  <div>
    <div class="row">
      <div class="col-3 p-sm-1">
        <b-form-select v-show="listUsers && selectedRows.length > 0" v-model="selected" class="select--users--assign">
          <template #first>
            <b-form-select-option value="" disabled>Por favor selecciona un usuario para asignar</b-form-select-option>
          </template>
          <b-form-select-option class="fa" :value="'all'">
            Distribución masiva
          </b-form-select-option>
          <b-form-select-option class="fa" v-for="option in listUsersAssign" :key="option.id" :value="option.value">
            {{ option.name ? option.name + ' ' + option.last_name : option.text }}
          </b-form-select-option>
        </b-form-select>
      </div>
    </div>
    <table-render :schema="schema" :rows="rows" :showCheckboxes="true" :selectedRows.sync="selectedRows" :fixed="true">
      <template #prioritySlot="scope">
        <feather-icon icon="CircleIcon" 
          :style="[{'color': `${scope.rowdata.priority.color ? scope.rowdata.priority.color : 'red'}`, fill: `${scope.rowdata.priority.color ? scope.rowdata.priority.color : 'red'}`}] " />
        {{scope.rowdata.priority.label}}
      </template>
      <template #actionsSlot="scope">
        <div class="container--action">
          <drop-down-assign
            :indexTicket="scope.rowdata.id"
            :listOptions="listUsersAssign"
            @updateListUsers="setUserAssign($event)">
          </drop-down-assign>
          <b-button variant="flat-success" v-b-tooltip.hover title="Ver Delivery" class="p-0" @click="openDelivery(scope.rowdata)"><feather-icon icon="TruckIcon" /></b-button>
          <b-button variant="flat-success" v-b-tooltip.hover title="Ver Ticket" class="p-0" @click="openTicket(scope.rowdata)"><feather-icon icon="FileTextIcon" /></b-button>
          <!-- <b-button variant="flat-success" v-b-tooltip.hover title="Completar tarea" class="p-0" @click="completeTask(scope.rowdata.id)"><feather-icon icon="CheckIcon" /></b-button> -->
        </div>
      </template>
      <template #countrySlot="scope">
        <p>{{setCountry(scope.rowdata.country_code)}}</p>
      </template>
      <template #resolutionDateSlot="scope">
        <p class="m-0">{{scope.rowdata.resolution_date ? sliceString(scope.rowdata.resolution_date) : 'Sin información'}}</p>
      </template>
    </table-render>
    <pagination :pagination="pagination" :noDigits="pagination.noShowDigits" :showSize="true"/>
    <modal-massive-assign :massiveAssign="massiveAssign" @resetSelect="selected = null" @setUsersService="setUsersService"></modal-massive-assign>
  </div>
</template>
<script>
import BaseServices from '@/store/services/index'
import dropDownAssign from './dropDownAssign.vue'
import { environment } from '@/environments/environment'
import ModalMassiveAssign from './modalMassiveAssign.vue'
export default {
  props: ['listAssign', 'listUsers', 'permission', 'pagination'],
  components: {
    dropDownAssign,
    ModalMassiveAssign
  },
  data() {
    return {
      baseService: new BaseServices(this),
      schema: [],
      rows: [],
      actions: [],
      countries: {},
      selectedRows: [],
      selected: {},
      massiveAssign: {
        listUsers: [],
        listTickets: []
      }
    }
  },
  mounted() {
    this.setData()
  },
  computed: {
    dataUser() {
      return this.$session.get('cas_user')
    },
    listUsersAssign() {
      return this.listUsers && this.listUsers.length > 0 ? [...this.listUsers] : []
    }
  },
  watch: {
    listAssign: {
      handler(value) {
        if (value) {
          this.rows = [...value]
        }
      },
      deep: true
    },
    selected: {
      handler(value) {
        this.setUsers(this.selectedRows, value)
      }
    },
    'pagination.page'(value) {
      this.$emit('changePagination', value)
    },
    'pagination.limit'(value) {
      this.$emit('changeLimit', value)
    }
  },
  methods: {
    sliceString(dateString) {
      return dateString.slice(0, 10)
    },
    setData() {
      this.schema = [
        {label: 'ID', key: 'delivery_id', sortable: true},
        {label: 'Courier', key: 'ticket.carrier_name', sortable: true},
        {label: 'Empresa', key: 'ticket.company_name', sortable: true},
        {label: 'País', key: 'countrySlot', useSlot: true},
        {label: 'Categoría', key: 'category.category_name', sortable: true},
        {label: 'Asunto', key: 'ticket.current_type_name', sortable: true},
        {label: 'Estado Tracking', key: 'ticket.current_delivery_status_name', sortable: true},
        {label: 'Estado Ticket', key: 'ticket.current_status_agent_name', sortable: true},
        {label: 'Tipo de cuenta', key: 'ticket.contract_type', sortable: true},
        {label: 'Prioridad', key: 'prioritySlot', useSlot: true},
        {label: 'Fecha de resolución (etiqueta)', key: 'resolutionDateSlot', useSlot: true},
        {label: 'Acciones', key: 'actionsSlot',  useSlot: true, class: ['text-center thead--light']}
      ]
      this.countries = this.$i18nImport('es').es
    },
    setCountry(country) {
      return this.countries[country]
    },
    setUsers(idTicket, idUser) {
      if (idUser && idUser !== 'all') {
        const idUserSend = [idUser]
        const queryParams = {
          bo_user_id: idUserSend,
          tickets_assignment_id: idTicket,
          user: this.$session.get('cas_user').email
        }
        this.setUsersService(queryParams)
      } else if (idUser && idUser === 'all') {
        this.massiveAssign = {
          listTickets: [...idTicket],
          listUsers: [...this.listUsers]
        }
        this.$bvModal.show('modalMassiveAssign')
      }
    },
    setUsersService(queryParams) {
      this.baseService.callService('setUsersAssign', queryParams)
        .then(response => {
          if (queryParams.bo_user_id.length > 1) {
            this.$success(`Tickets Asignados: ${response.data.data_update}, Tickets no Asignados ${response.data.data_not_update}`)
          } else if (queryParams.tickets_assignment_id.length > 1 && queryParams.bo_user_id.length === 1) {
            this.$success(`Tickets Asignados: ${response.data.data_updated}`)
          } else {
            this.$success('Ticket asignado exitosamente')
          }
          this.updateFilters()
        })
        .catch((err) => {
          this.$alert('Ha ocurrido un error, intenta nuevamente')
          console.error(err)
        })
    },
    setUserAssign(data) {
      this.setUsers([data.indexTicket], data.id)
    },
    completeTask(idTicket) {
      if (idTicket) {
        const queryParams = {
          tickets_assignment_id: idTicket
        }
        this.baseService.callService('setCompleteAssign', queryParams)
          .then(response => {
            this.updateFilters()
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    updateFilters() {
      this.selected = null
      this.selectedRows = []
      this.$emit('updateList', true)
    },
    openTicket(data) {
      window.open(`${environment.platformUrl}/${ !!data.ticket && !!data.ticket.delivery_type && data.ticket.delivery_type === 'return' ? 'return-deliveries' : 'tickets'}/${data.delivery_id}`, '_blank')
    },
    openDelivery(data) {
      window.open(`${environment.platformUrl}/${ !!data.ticket && !!data.ticket.delivery_type && data.ticket.delivery_type === 'return' ? 'return-deliveries' : 'deliveries'}/${data.delivery_id}`, '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
  .select--users--assign {
    border-color: #165191;
    color: #165191;
  }
  .container--action {
    display: flex;
    gap: 1rem;
    text-align: center;
    justify-content: center;
  }
</style>